<template>
	<div>
		<topmenu :comname="comname"></topmenu>
		<div class="flex">
			<div class="widht1200 bothendsleft">
				<div class="t-map">
					您当前的位置：
					<span>{{ maptitle }}</span>
				</div>
				<!-- <div class="widht200 fl">
          <contact></contact>
				</div>-->
				<div class="widht1200 fr">
					<ul class="ulTop">
						<li :class="20==topIndex?'on':''" @click="son('',20)">全部</li>

						<li :class="idx==topIndex?'on':''" @click="son(item,idx)" v-for="(item,idx) in menu" :key="idx" v-html="filterKeywords(item.vName)">
							<!-- {{item.vName}} -->
						</li>
					</ul>

					<div v-if="isshowlist" v-loading="loading">
						<ul class="compaylist">
							<li v-for="item in list" :key="item.id" :class="item.nvcCompanyName?'': 'op0'">
								<a :href="item.url" :rel="noFollow(item.url)" target="_blank">
									<el-image
										:src="item.nvcLogo?item.nvcLogo:require('@/assets/images/dtmr.png')"
										fit="contain"
										:alt="item.nvcLogo?item.nvcCompanyName:''"
										:title="item.nvcCompanyName"
										style="width:90px; height:90px; border-radius: 5px; float: left; margin-right:20px"
									></el-image>
								</a>
								<span :title="item.nvcCompanyName" class="company" v-html="filterKeywords(item.nvcCompanyName)">
									<!-- {{item.nvcCompanyName!=null&&item.nvcCompanyName.length>10?comJs.limitNum(item.nvcCompanyName,10):item.nvcCompanyName}} -->
								</span>
								<span class="spanPublic">{{item.nvcContactPerson }} {{item.nvcContactPost}}</span>
								<span class="spanPublic">电话：{{item.nvcContactNumber}}</span>
								<span class="productDetails" v-html="filterKeywords(item.nvcProducts)"></span>
								<a class="enter color182" :href="item.url" :rel="noFollow(item.url)" target="_blank">点击进入展厅</a>
							</li>
						</ul>
						<el-pagination
							background
							layout="prev, pager, next"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:page-count="totalPage"
							:current-page="currentPage"
							:page-size="pagesize"
							:hide-on-single-page="true"
							class="t_page"
						></el-pagination>
					</div>
					<nocont v-else></nocont>
				</div>
			</div>
		</div>
		<bottominfo></bottominfo>
	</div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
	name: "enterprise",
	data() {
		return {
			id: "",
			maptitle: "",
			comname: "",
			list: [],
			isshowlist: true,
			loading: true,
			currentPage: 1, //当前页
			pagesize: 25, //每页显示条数
			totalPage: 0, //总页数
			listData: [
				{
					title: "深圳市*****技有限公司",
				},
				{
					title: "北京*****技装备有限公司",
				},
				{
					title: "杭州*****术有限公司",
				},
				{
					title: "天津******有限公司",
				},
				{
					title: "天津****发展有限公司",
				},
				{
					title: "安徽*******设备有限公司",
				},
				{
					title: "北京****科技发展有限公司",
				},
				{
					title: "山东*****设备有限公司",
				},
				{
					title: "陇南******研究科技有限公司",
				},
				{
					title: "长春****科技有限公司",
				},
				{
					title: "中科*****（苏州）科技有限公司",
				},
				{
					title: "德尼****技术（苏州）有限公司",
				},
				{
					title: "河北****具制造有限公司",
				},
				{
					title: "北京****科技管理中心",
				},
				{
					title: "霸州市*****机具有限公司",
				},
				{
					title: "河北**贸易有限公司",
				},
				{
					title: "威海****设备制造有限公司",
				},
				{
					title: "北京*****设备有限公司",
				},
				{
					title: "梧州***科技有限公司",
				},
				{
					title: "潍坊****机械有限公司",
				},
				{
					title: "汝南县**应急设备有限公司",
				},
				{
					title: "深圳市**科技有限公司",
				},
				{
					title: "航天****技术股份有限公司",
				},
				{
					title: "湖南****装备科技有限公司",
				},
				{
					title: "北京中关村******研究院有限公司",
				},

				{
					title: "浙江****工程有限公司",
				},
				{
					title: "鹰眼****有限公司",
				},

				{
					title: "西安****工程新材料有限公司",
				},
				{
					title: "安徽*****技术有限公司",
				},
				{
					title: "梅思安****设备有限公司",
				},
				{
					title: "新兴****工厂",
				},
				{
					title: "中国******工程局有限公司",
				},
				{
					title: "河南*****科技股份有限公司",
				},
				{
					title: "奥来****有限公司",
				},
			],
			dialogFormVisible: false,
			form: {
				companyName: "",
				contactsName: "",
				phone: "",
			},
			rules: {
				companyName: [
					{
						required: true,
						message: "请输入企业名称",
						trigger: "change",
					},
				],
				contactsName: [
					{
						required: true,
						message: "请输入联系人",
						trigger: "change",
					},
				],
				phone: [
					{
						required: true,
						message: "请输入联系方式",
						trigger: "change",
					},
				],
			},
			formLabelWidth: "95px",
			menu: [],
			topIndex: 20,
		};
	},
	components: {
		topmenu,
		bottominfo,
		contact,
		nocont,
	},
	created() {
		if (this.$route.query.id) {
			this.id = this.$route.query.id;
		}
		if (this.id == "1") {
			this.maptitle = "线上云展";
			this.comname = "enterprise";
		} else {
			this.maptitle = "观展企业";
			this.comname = "enterprise2";
		}
		this.getinformation();
	},
	computed: {
		defaultOption() {
			return {
				step: 0.4, // 数值越大速度滚动越快
				limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			};
		},
	},
	watch: {
		$route: function (to, from) {
			this.id = this.$route.query.id;
			if (this.id == "1") {
				this.maptitle = "参展企业";
				this.comname = "enterprise";
			} else {
				this.maptitle = "观展企业";
				this.comname = "enterprise2";
			}
			this.getinformation();
		},
		//监听翻页
		currentPage: function (newVal, oldVal) {
			this.loading = true;
			this.getinformation();
		},
	},
	methods: {
		handleSizeChange: function (size) {
			//翻页
			this.pagesize = size;
		},
		handleCurrentChange: function (currentPage) {
			//翻页
			this.currentPage = currentPage;
		},
		noFollow(n) {
			if (n && n.indexOf("086") == -1) {
				return "nofollow";
			} else {
				return "";
			}
		},
		// 保存
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$axios
						.post("/api/rally/saveExhibitionApply", this.form)
						.then((res) => {
							if (res.data.isSuccess == 1) {
								this.$message({
									message: "申请成功",
									center: true,
									type: "success",
								});
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
							} else {
								this.$message.error(res.data.message);
							}
						});
				} else {
					// this.$message({message:'请检查表单是否填写正确!',center: true,type:'error'});
					return false;
				}
			});
		},
		// 重置
		resetForm(formName) {
			this.dialogFormVisible = false;
			this.$refs[formName].resetFields();
		},
		filterKeywords(label) {
			let labels = label ? label : "";
			if (labels.indexOf("刚玉") != -1) {
				let newData = this.comJs.keywordShow("刚玉", labels);
				if (newData.indexOf("矾土") != -1) {
					return this.comJs.keywordShow("矾土", newData);
				} else {
					return newData;
				}
			} else if (labels.indexOf("矾土") != -1) {
				return this.comJs.keywordShow("矾土", labels);
			} else {
				return labels;
			}
		},
		getinformation(id) {
			//参展企业
			console.log(id, "58");
			let data = {
				current: this.currentPage,
				size: this.pagesize,
				isPage: true,
				itype: this.id,
				iCompanyTypeId: id,
			};
			console.log(data);
			this.$axios
				.post(
					"/api/app/exhibitionCompany/getExhibitionCompanyPageList",
					data
				)
				.then((res) => {
					console.log(res[2].companyTypes[0].vName, "644545");
					this.loading = false;
					this.totalPage = res[1].pages;
					this.menu = res[2].companyTypes;
					console.log(this.menu);
					if (res[0].length > 0) {
						let infordata = res[0];
						console.log(infordata, "1111111111");
						this.list = infordata;
						this.isshowlist = true;
						if (this.list.length % 3 == 2) this.list.push("");
					} else {
						this.isshowlist = false;
					}
				});
		},
		son(item, idx) {
			this.topIndex = idx;
			this.getinformation(item.id);
		},
	},
};
</script>
<style>
.color_orange {
	color: #e70012;
}
</style>
<style scoped>
.flex {
	display: flex;
	background-color: #f4f8ff;
}
.el-dialog__header {
	/* float: left;
  line-height: 5px; */
	text-align: left;
}
.el-dialog__header {
	padding: 15px 20px 15px;
	background: #f8f8f8;
}
.el-dialog__title {
	font-size: 16px;
	line-height: inherit;
}
.apply {
	text-align: right;
	padding: 20px;
}
.el-button--goon.is-active,
.el-button--goon:active {
	background: #073290;
	border-color: #073290;
	color: #fff;
}

.el-button--goon:focus,
.el-button--goon:hover {
	background: #395ba6;
	border-color: #395ba6;
	color: #fff;
}

.el-button--goon {
	color: #fff;
	background-color: #073290;
	border-color: #073290;
	font-size: 15px;
}

.t_page {
	margin-top: 30px;
}
.bothendsleft {
	position: relative;
}
.bothendsright {
	position: absolute;
	right: 10%;
	width: 120px;
	margin-top: 47px;
	overflow: hidden;
	left: 50%;
	margin-left: 650px;
}
.seamless-warp {
	overflow: hidden;
	width: 120px;
	height: 300px;
}
.top-title {
	padding: 15px 0;
	color: #fff;
	font-weight: bold;
	background: #073290;
}
.seamless-warp span {
	text-align: left;
	padding: 10px 15px;
}
.bothendsright li {
	background: #fff;
	border-top: solid 1px #e4e4e4;
	cursor: pointer;
}
.bothendsright li:hover {
	background: #e3edf9;
}
.on {
	background-color: #182fb3 !important;
	color: white;
}
.ulTop {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.ulTop li {
	width: 120px;
	height: 50px;
	background-color: #ffffff;
	border: 1px solid #eaeaea;
	border-radius: 25px;
	text-align: center;
	line-height: 50px;
	margin: 0 12px;
	cursor: pointer;
}

.compaylist {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 40px;
}
.compaylist li {
	width: 338px;
	/* height: 190px; */
	padding: 30px 24px;
	border-radius: 5px;
	margin-bottom: 20px;
	background-color: white;
}
.compaylist li .el-image {
	border: 1px solid #e4e4e4;
}
.spanPublic {
	display: inline-block;
	width: 225px;
	font-size: 14px;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}
.productDetails {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	margin-top: 30px;
	text-align: left;
	line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}
.enter {
	display: block;
	width: 100%;
	text-align: right;
	margin-top: 24px;
	margin-right: 10px;
}
.compaylist li .company {
	display: inline-block;
	width: 225px;
	font-size: 18px;
	font-weight: 700;
	color: #333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	margin-top: 12px;
}
</style>